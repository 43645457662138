<template>
    <div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div class="flex-auto p-4">
            <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                <h5 class="text-blueGray-400 uppercase font-bold text-xs"> {{subtitle}} 
                </h5>
                <span class="font-semibold text-xl text-blueGray-700"> {{value}} 
                </span>
            </div>
            <div class="relative w-auto pl-4 flex-initial">
                <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full" :class="statincolor">
                </div>
            </div>
            </div>
            <p class="text-sm text-blueGray-400 mt-4">
            <span class="mr-2 text-emerald-500">
                <i class="fas fa-arrow-up">
                </i> {{secondValue}}
            </span>
            <span class="whitespace-nowrap">{{description}}
            </span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        subtitle: String,
        value: Number,
        statincolor: String,
        secondValue: String,
        description: String
    },
}

</script>