<template>
  <div>
    <Head :title="form.name" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/events">Events</Link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="event.deleted_at" class="mb-6" @restore="restore"> This event has been deleted. </trashed-message>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Event name" />
          <text-input v-model="form.location" :error="form.errors.location" class="pb-8 pr-6 w-full lg:w-1/2" label="Location" />
          <text-area-input v-model="form.description" :error="form.errors.description" class="pb-8 pr-6 w-full lg:w-1/1" label="Description" />
          <date-input v-model="form.start_date" :error="form.errors.start_date" class="pb-8 pr-6 w-full lg:w-1/2" label="Start date" />
          <date-input v-model="form.end_date" :error="form.errors.end_date" class="pb-8 pr-6 w-full lg:w-1/2" label="End date" />
          <loading-button class="btn-indigo mb-10" type="button"  @click="downloadReport"><icon name="printer" class="block mr-2 w-4 h-4 fill-white"/>PDF</loading-button>
          <loading-button class="ml-4 btn-indigo mb-10" type="button"  @click="downloadCsv"><icon name="printer" class="block mr-2 w-4 h-4 fill-white"/>CSV</loading-button>
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button v-if="!event.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Delete Events</button>
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update Events</loading-button>
        </div>
      </form>
    </div>
    <div class="mt-8">
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form2.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Attending:</label>
          <select v-model="form2.trashed" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="attending">Attending</option>
            <option value="notAttending">Not Attending</option>
          </select>
        </search-filter>
        <Link class="btn-indigo" :href="`/attendees/${event.id}/create`">
          <span>Add</span>
          <span class="hidden md:inline">&nbsp;Attendee</span>
        </Link>
      </div>
      <div class="bg-white rounded-md shadow overflow-x-auto" scroll-region>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left font-bold">
              <th class="pb-4 pt-6 px-6">Name</th>
              <th class="pb-4 pt-6 px-6">Company</th>
              <th class="pb-4 pt-6 px-6">City</th>
              <th class="pb-4 pt-6 px-6">Checkin at</th>
            </tr>
          </thead>
          <tbody>
            <!-- if attendee.is_checkin, set the row color to green -->

            <tr v-for="attendee in event.attendees.data" :key="attendee.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
              <td class="border-t">
                <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/attendees/${attendee.id}/edit`">
                  <icon v-if="attendee.is_checked_in == 1" name="check" class=" flex-shrink ml-2 w-4 h-4 fill-green-500" />
                  <icon v-if="attendee.is_checked_in == 0" name="uncheck" class=" flex-shrink ml-2 w-4 h-4 " />
                  &nbsp{{ attendee.name }}
                </Link>
              </td>
              <td class="border-t">
                <Link class="flex items-center px-6 py-4" :href="`/attendees/${attendee.id}/edit`" tabindex="-1">
                  {{ attendee.company }}
                </Link>
              </td>
              <td class="border-t">
                <Link class="flex items-center px-6 py-4" :href="`/attendees/${attendee.id}/edit`" tabindex="-1">
                  {{ attendee.city }}
                </Link>
              </td>
              <td class="border-t">
                <Link class="flex items-center px-6 py-4" :href="`/attendees/${attendee.id}/edit`" tabindex="-1">
                  {{ attendee.check_in_at }}
                </Link>
              </td>
              <td class="w-px border-t">
                <Link class="flex items-center px-4" :href="`/attendees/${attendee.id}/edit`" tabindex="-1">
                  <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
                </Link>
              </td>
            </tr>
            <tr v-if="event.attendees.length === 0">
              <td class="px-6 py-4 border-t" colspan="3">No Attendees found.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination class="mt-6" :links="event.attendees.links" />
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import pickBy from 'lodash/pickBy'
import DateInput from '@/Shared/DateInput'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput'
import TextAreaInput from '@/Shared/TextAreaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    DateInput,
    TextInput,
    TextAreaInput,
    TrashedMessage,
    Pagination,
    SearchFilter,
  },
  layout: Layout,
  props: {
    filters: Object,
    event: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: this.event.name,
        location: this.event.location,
        description: this.event.description,
        start_date: this.event.start_date,
        end_date: this.event.end_date,
      }),
      form2: this.$inertia.form({
        search: this.filters.search || '',
      }),
    }
  },
  watch: {
    form2: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/events/'+this.event.id+'/edit', pickBy(this.form2), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    update() {
      this.form.put(`/events/${this.event.id}`)
    },
    destroy() {
      if (confirm('Are you sure you want to delete this event?')) {
        this.$inertia.delete(`/events/${this.event.id}`)
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this event?')) {
        this.$inertia.put(`/events/${this.event.id}/restore`)
      }
    },
    downloadReport() {
      window.open(`/events/${this.event.id}/report`)
    },
    downloadCsv() {
      window.open(`/events/${this.event.id}/reportcsv`)
    },
  },
}
</script>
