<template>
  <div>
    <Head title="Dashboard" />
    <h1 class="mb-8 text-3xl font-bold">Dashboard</h1>
    <p class="mb-8 leading-normal">Hey Welcome to QR Eventick, ;)</p>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
        <app-card-status
          subtitle="Total Events"
          :value="events"
          statincolor="bg-red-500"
          secondValue="0 Event"
          description="last month"
        />
      </div>
      <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
        <app-card-status
          subtitle="Ticket Sold"
          :value="tickets"
          statincolor="bg-green-500"
          secondValue=""
          description=""
        />
      </div>
      <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
        <app-card-status
          subtitle="Attending Events"
          :value="attending"
          statincolor="bg-orange-500"
          secondValue=""
          description=""
        />
      </div>
    </div>
  </div>
  <div class="fixed mb-4 bottom-0 w-full ">
    <p class="text-center text-gray-500 text-xs">
      &copy;2023 QR Eventick.id All rights reserved.
    </p>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import AppCardStatus from '@/Shared/AppCardStatus'

export default {
  components: {
    Head,
    AppCardStatus,
  },
  layout: Layout,
  props: {
    events : Number,
    tickets : Number,
    attending : Number,
  }
}
</script>
