<template>
  <div>
    <Head title="Create Attendees" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" :href="'/events/'+event.id+'/edit'">{{ event.name }}</Link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.event_id" :error="form.errors.event_id" class="hidden" label="event" />
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="name" />
          <text-input v-model="form.email" :error="form.errors.email" class="pb-8 pr-6 w-full lg:w-1/2" label="email" />
          <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="address" />
          <text-input v-model="form.city" :error="form.errors.city" class="pb-8 pr-6 w-full lg:w-1/2" label="city" />
          <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 pr-6 w-full lg:w-1/2" label="phone" />
          <text-input v-model="form.company" :error="form.errors.company" class="pb-8 pr-6 w-full lg:w-1/2" label="company" />
          <text-input v-model="form.attribut1" :error="form.errors.attribut1" class="pb-8 pr-6 w-full lg:w-1/2" label="attribut1" />
          <text-input v-model="form.attribut2" :error="form.errors.attribut2" class="pb-8 pr-6 w-full lg:w-1/2" label="attribut2" />
          <text-input v-model="form.attribut3" :error="form.errors.attribut3" class="pb-8 pr-6 w-full lg:w-1/2" label="attribut3" />          
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Attendees</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  layout: Layout,
  remember: 'form',
  props: {
    event: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
          event_id: this.event.id,
          name: null,
          email: null,
          address: null,
          city: null,
          phone: null,
          company: null,
          attribut1: null,
          attribut2: null,
          attribut3: null,
      }),
    }
  },
  methods: {
    store() {
      this.form.post('/attendees')
    },
  },
}
</script>
