<template>
  <div>
    <Head title="Report" />
    <h1 class="mb-8 text-3xl font-bold">QR Scanner</h1>
    <div>
        
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'


export default {
  components: {
    Head,
  },
  layout: Layout,
}
</script>
