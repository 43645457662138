<template>
  <div>
    <Head :title="form.name" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" :href="'/events/'+this.attendee.event_id+'/edit'">{{ attendee.event_name }}</Link><br/>
      {{ form.name }} <span v-if="attendee.is_checked_in == 1" class="text-green-500 font-bold text-sm"> (Checked In)</span>
    </h1>
    <trashed-message v-if="attendee.deleted_at" class="mb-6" @restore="restore"> This attendee has been deleted. </trashed-message>
    <div class="max-w-3xl mb-4 bg-white rounded-md shadow overflow-hidden">
      <div class="flex flex-wrap -mb-8 -mr-6 p-8">
        <!-- show image, widht 300 height 300  -->
        <div class="pb-8 pr-6">
          <!-- link to scanner page -->
          <a :href="'/scanner/'">
            <!-- show qr code -->
            <img class="w-48 h-48" :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+form.ticket_hash" alt="Image" />
          </a>

          <loading-button v-if="attendee.is_checked_in == 0" class="btn-indigo hover:underline mt-4" tabindex="-1" type="button" @click="checkin">Checkin</loading-button>
          <loading-button v-if="attendee.is_checked_in == 1" class="btn-indigo bg-orange-500 hover:underline mt-4" tabindex="-1" type="button" @click="checkout">CheckOut</loading-button>
        </div>
      </div>
    </div>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.event_id" :error="form.errors.event_id" class="hidden" label="event_id" />
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="name" />
          <text-input v-model="form.email" :error="form.errors.email" class="pb-8 pr-6 w-full lg:w-1/2" label="email" />
          <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="address" />
          <text-input v-model="form.city" :error="form.errors.city" class="pb-8 pr-6 w-full lg:w-1/2" label="city" />
          <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 pr-6 w-full lg:w-1/2" label="phone" />
          <text-input v-model="form.company" :error="form.errors.company" class="pb-8 pr-6 w-full lg:w-1/2" label="company" />
          <text-input v-model="form.attribut1" :error="form.errors.attribut1" class="pb-8 pr-6 w-full lg:w-1/2" label="attribut1" />
          <text-input v-model="form.attribut2" :error="form.errors.attribut2" class="pb-8 pr-6 w-full lg:w-1/2" label="attribut2" />
          <text-input v-model="form.attribut3" :error="form.errors.attribut3" class="pb-8 pr-6 w-full lg:w-1/2" label="attribut3" />          
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button v-if="!attendee.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Delete Attendees</button>
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update Attendees</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  layout: Layout,
  props: {
    attendee: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        event_id: this.attendee.event_id,
        name: this.attendee.name,
        email: this.attendee.email,
        address: this.attendee.address,
        city: this.attendee.city,
        phone: this.attendee.phone,
        company: this.attendee.company,
        attribut1: this.attendee.attribut1,
        attribut2: this.attendee.attribut2,
        attribut3: this.attendee.attribut3,
        ticket_hash: this.attendee.ticket_hash,
        is_valid: this.attendee.is_valid,
        is_checked_in: this.attendee.is_checked_in,
        check_in_at: this.attendee.check_in_at,
        check_in_by: this.attendee.check_in_by,
      }),
    }
  },
  methods: {
    checkin() {
      this.form.put(`/attendees/${this.attendee.id}/checkin`)
    },
    checkout() {
      this.form.put(`/attendees/${this.attendee.id}/checkout`)
    },
    update() {
      this.form.put(`/attendees/${this.attendee.id}`)
    },
    destroy() {
      if (confirm('Are you sure you want to delete this attendee?')) {
        this.$inertia.delete(`/attendees/${this.attendee.id}`)
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this attendee?')) {
        this.$inertia.put(`/attendees/${this.attendee.id}/restore`)
      }
    },
  },
}
</script>
