<template>
  <div>
    <Head title="Scanner" />
    <h1 class="mb-8 text-3xl font-bold">QR Scanner</h1>
    <div class="">
        <video id="myvideo" width="448" height="300" muted autoplay playsinline></video>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import jsQR from 'jsqr'

export default {
  components: {
    Head,
  },
  layout: Layout,
  created() {
    this.cam2video();
    setInterval(() => {
      this.getQr();
    }, 500);
  },
  methods: {
    async cam2video() {
      // Get image with external camera priority (trick is to make the image size small)
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
          width: 448,
          height: 300,
        },
      });
      // Stream to video tag
      const video = document.getElementById('myvideo');
      video.srcObject = stream;
      video.play();
    },
    getQr() {
      // Get image
      const video = document.getElementById('myvideo');
      // Create a canvas element that is not actually displayed to temporarily transfer the image data
      const canvas = document.createElement('canvas');
      canvas.width = video.width;
      canvas.height = video.height;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0);
      // Get as ImageData
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      // Analyze with jsQR
      const result = jsQR(imageData.data, imageData.width, imageData.height);
      // if result make a request to the server
      if (result) {
        console.log(result.data);
        // stop the video stream
        this.$inertia.get(`/scanner/check/${result.data}`)
        video.srcObject.getTracks().forEach((track) => {
          track.stop();
        });
      }
    },
  },
}
</script>
